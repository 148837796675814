import React, { Component } from "react";
import { Row, Col, Form, Modal, Button, Spinner, Table } from "react-bootstrap";

class ModalGoals extends Component {
  state = {};
  handleOpen() {}
  render() {
    return (
      <React.Fragment>
        <Modal
          backdrop="static"
          keyboard={false}
          onEntered={this.handleOpen}
          show={this.props.showGoalsModal}
          onHide={this.props.handleClose}
          dialogClassName="editModal"
        >
          <Modal.Header closeButton={this.props.goalsModalClosable}>
            <div style={{ width: "100%", textAlign: "center" }}>
              <h3>Setup your Team Goals</h3>
            </div>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.props.updateGoals}>
              <div style={{ textAlign: "center" }}>
                <div style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>TEAM</div>
                <div className="newGoals_div_container">
                  <div className="newGoals_div">
                    <Form.Group controlId="formPerPoundShipment">
                      <Form.Label>Team Hourly Scans Goal</Form.Label>
                      <Form.Control
                        required
                        defaultValue={this.props.teamScansGoalHourly}
                        type="number"
                        step="1"
                        name="teamScansGoalHourly"
                        className="newGoals_input_center"
                      />
                    </Form.Group>
                  </div>
                  <div className="newGoals_div">
                    <Form.Group controlId="formPerPoundShipment">
                      <Form.Label>Team Daily Scans Goal</Form.Label>
                      <Form.Control
                        required
                        defaultValue={this.props.teamScansGoal}
                        type="number"
                        step="1"
                        name="teamScansGoal"
                        className="newGoals_input_center"
                      />
                    </Form.Group>
                  </div>
                  {/* <div className="newGoals_div">
                    <Form.Group controlId="formPerPoundShipment">
                      <Form.Label>Team Daily Profit Goal (in $)</Form.Label>
                      <Form.Control
                        required
                        defaultValue={this.props.teamProfitGoal}
                        type="number"
                        step="1"
                        name="teamProfitGoal"
                        className="newGoals_input_center"
                      />
                    </Form.Group>
                  </div> */}
                  <div className="newGoals_div">
                    <Form.Group controlId="formPerPoundShipment">
                      <Form.Label>Ideal Scans per Hour Goal</Form.Label>
                      <Form.Control
                        required
                        defaultValue={this.props.teamScansGoalIdealPerHour}
                        type="number"
                        step="1"
                        name="teamScansGoalIdealPerHour"
                        className="newGoals_input_center"
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "10px", textAlign: "center" }}>
                <div style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>USERS</div>
                <div className="newGoals_div_container">
                  <div className="newGoals_div">
                    <Form.Group controlId="formPerPoundShipment">
                      <Form.Label>Individual users scan goal</Form.Label>
                      <Form.Control
                        required
                        defaultValue={this.props.usersScansGoal}
                        type="number"
                        step="1"
                        name="usersScansGoal"
                        className="newGoals_input_center"
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: "20px", textAlign: "center" }}>
                {this.props.isUpdatingGoals === true ? (
                  <Spinner animation="border" role="status"></Spinner>
                ) : (
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                )}
              </div>
              {/* <div>
                {"users" in this.props.goalsData && (
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>Name</th>
                        <th style={{ textAlign: "center" }}>Scan Goal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.goalsData.users.map((user) => (
                        <tr>
                          <td style={{ textAlign: "center", fontWeight: "bold" }}>{user.first_name}</td>
                          <td style={{ textAlign: "center" }}>
                            {user.profiles.length > 0 ? (
                              user.profiles.map((profile) => (
                                <div style={{ marginBottom: "10px" }}>
                                  <div>{profile.name}</div>
                                  <div>
                                    <Form.Control
                                      type="number"
                                      step="1"
                                      name="perPound"
                                      className="newGoals_input_center"
                                    />
                                  </div>
                                </div>
                              ))
                            ) : (
                              <Form.Control type="number" step="1" name="perPound" className="newGoals_input_center" />
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div> */}
            </Form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalGoals;
