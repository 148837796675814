import React, { Component } from 'react';
import { Row, Col, Form, Button, Spinner, Alert, Container } from 'react-bootstrap';
import '../assets/style/login.css';
import { submitLogin } from '../includes/Helper';
import auth from '../Auth';

class Login extends Component {
  state = {
    logingin: false,
    isInvalidLogin: -1,
    invalidLoginMessage: '',
  };

  constructor() {
    super();
    this.handleLogin = this.handleLogin.bind(this);
  }

  async handleLogin(event) {
    event.preventDefault();
    const user_email = event.target.elements.user_email.value;
    const user_password = event.target.elements.user_password.value;

    this.setState({ logingin: true });
    const response = await submitLogin(user_email, user_password);

    this.setState({ logingin: false });

    if (response.status === false) {
      this.setState({ isInvalidLogin: 1, invalidLoginMessage: response.error });
    } else {
      console.log(response.data);
      auth.login(response.data, () => {
        this.props.history.push('home');
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <div className="div-form-container">
                <div style={{ borderBottom: '1px solid #ddd', paddingBottom: '20px' }}>
                  <h2>PIQ Team Dash</h2>
                  <p>See statistics and scan metric of team members</p>
                  <h3>LOGIN</h3>
                </div>

                {this.state.isInvalidLogin === 1 && (
                  <div style={{ marginTop: '20px' }}>
                    <Alert variant="danger">
                      <span>{this.state.invalidLoginMessage}</span>
                    </Alert>
                  </div>
                )}
                <div style={{ marginTop: '35px' }}>
                  <Form onSubmit={this.handleLogin}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Control
                        style={{ textAlign: 'center' }}
                        type="text"
                        name="user_email"
                        placeholder="Enter email"
                      />
                    </Form.Group>

                    <Form.Group style={{ marginTop: '30px' }} controlId="formBasicPassword">
                      <Form.Control
                        style={{ textAlign: 'center' }}
                        type="password"
                        name="user_password"
                        placeholder="Password"
                      />
                    </Form.Group>

                    {this.state.logingin ? (
                      <Spinner style={{ marginTop: '33px' }} animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      <Button variant="primary" type="submit" style={{ marginTop: '30px' }}>
                        Submit
                      </Button>
                    )}
                  </Form>
                </div>

                {/* <div style={{ marginTop: "20px" }}>
                  <Button variant="link" onClick={() => this.props.history.push("/signup")}>
                    Don't have an account? Click here to create one.
                  </Button>
                </div>

                <div style={{ marginTop: "20px" }}>
                  Questions? Email <a href="mailto:caleb@thebookflipper.com">caleb@thebookflipper.com</a>
                </div> */}

                {/* <div style={{ marginTop: "30px", borderTop: "1px solid #ddd", paddingTop: "10px" }}>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=dDg2ObHuJC0">
                    * Click here to see how to use this free tool *
                  </a>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Login;
