import React, { Component } from "react";
import { Row, Container, Col } from "react-bootstrap";
import TopMenu from "../components/TopMenu";
import { callApi } from "../includes/Helper";
import Lottie from "react-lottie";
import animationData from "../assets/animations/25296-beer-bottle.json";
import { LineChart } from "react-chartkick";
import "chartkick/chart.js";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: this.props.history.location.state?.productName,
      products: this.props.history.location.state?.products,
      fetchingProductData: false,
      productData: { meta: { thumbail: "", description: "" }, history: [] },
      chartData: [],
    };
  }

  componentDidMount() {
    this.initiate();
  }

  getFormattedDate(dt) {
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;

    var yyyy = dt.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    return yyyy + "-" + mm + "-" + dd;
  }

  initiate() {
    this.setState({ fetchingProductData: true });
    callApi("items/history", { title: this.state.productName }, (response) => {
      if ("status" in response && response.status) {
        let priceHistory = {},
          estLowHistory = {},
          estHighHistory = {};
        for (let i = 0; i < response.data.history.length; i++) {
          priceHistory[this.getFormattedDate(new Date(response.data.history[i].last_updated))] =
            response.data.history[i].sold_price;
          estLowHistory[this.getFormattedDate(new Date(response.data.history[i].last_updated))] =
            response.data.history[i].estimate_low;
          estHighHistory[this.getFormattedDate(new Date(response.data.history[i].last_updated))] =
            response.data.history[i].estimate_high;
        }
        this.setState({
          chartData: priceHistory,
          productData: response.data,
          fetchingProductData: false,
        });
      } else this.setState({ fetchingProductData: false });
    });
  }

  calculateMedian(values) {
    if (values.length === 0) return 0;

    values.sort(function (a, b) {
      return a - b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  }

  render() {
    const defaultLottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <React.Fragment>
        <TopMenu adminData={this.state.admin_data} history={this.props.history} activeKey="/" />

        <Container fluid={true} className="page-dashboard">
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              {this.state.fetchingProductData ? (
                <Lottie options={defaultLottieOptions} height={400} width={400} />
              ) : (
                <div>
                  <div>
                    <i
                      style={{ fontSize: "32px", cursor: "pointer" }}
                      onClick={() =>
                        this.props.history.push({
                          pathname: `/`,
                          state: { products: this.state.products },
                        })
                      }
                      className="fa fa-arrow-circle-left"
                    />
                  </div>
                  <div style={{ display: "flex", marginTop: "20px" }}>
                    <div>
                      <img style={{ width: "100px" }} src={this.state.productData.meta.thumbnail} />
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      <h4>{this.state.productName}</h4>
                      <div>
                        <b>Estimated Low:</b> ${this.state.productData.meta.estimate_low}, <b>Estimated High:</b> $
                        {this.state.productData.meta.estimate_high}, <b>Mean:</b> $
                        {(
                          this.state.productData.history.reduce((sum, x) => sum + x.sold_price, 0) /
                          this.state.productData.history.length
                        ).toFixed(2)}
                        , <b>Median:</b> $
                        {this.calculateMedian(this.state.productData.history.map((his) => his.sold_price))},{" "}
                        <b>Number of Units Sold:</b> {this.state.productData.history.length}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.productData.meta.description.replace(/(<? *script)/gi, "illegalscript"),
                        }}
                      ></div>
                    </div>
                  </div>

                  <div style={{ marginTop: "30px" }}>
                    <LineChart data={this.state.chartData} />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Product;
