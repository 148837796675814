import React, { Component, createRef } from 'react';
import { Container, Row, Col, Button, InputGroup, FormControl, ProgressBar, Table } from 'react-bootstrap';
import TopMenu from '../components/TopMenu';
import { callApi } from '../includes/Helper';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap.min.css'; // use Theme(s)
import 'chart.js';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ModalGoals from '../components/ModalGoals';
import { PieChart, LineChart } from 'react-chartkick';
import CustomContentProgressbar from '../components/CustomCircularProgressbar';
import ReactSpeedometer from 'react-d3-speedometer';
import 'chart.js';
import auth from '../Auth';
import moment from 'moment';
moment().format();

class Home extends Component {
  state = {
    showGoalsModal: false,
    goalsModalClosable: false,
    isUpdatingGoals: false,
    isExcludeDuplicateScans: false,
    user_data: auth.getAdminData(),
    timeOutInterval: 3000,
    goalsData: {},
    scansByHour: [],
    userScansPie: [],
  };

  constructor() {
    super();
    this.updateGoals = this.updateGoals.bind(this);
    this.initiate = this.initiate.bind(this);
  }

  loadGoalsData(callback) {
    callApi(
      'admin/stats/goals',
      'POST',
      {
        user_id: this.state.user_data.admin_id,
        app_token: this.state.user_data.token,
        is_exclude_duplicate: this.state.isExcludeDuplicateScans,
      },
      (response) => {
        console.log(`Response`, response.data);
        this.setState({ goalsData: response.data });
        if ('data' in response) {
          if (response.data.team.goal_scans === null) {
            console.log(`Empty Goals`, response.data.team);
            this.setState({ showGoalsModal: true });
          } else {
            let userScansPie = [];
            for (let i = 0; i < response.data.users.length; i++) {
              userScansPie.push([
                response.data.users[i].display_name + ' (' + response.data.users[i].scans + ')',
                response.data.users[i].scans,
              ]);
            }
            this.setState({ userScansPie }, () => callback());
          }
        }
      }
    );
  }

  convertHourToAMPM(hour) {
    var h = hour % 12 || 12;
    var ampm = hour < 12 || hour === 24 ? 'AM' : 'PM';
    return h + ' ' + ampm;
  }
  loadScansByHour() {
    const timedifference = new Date().getTimezoneOffset();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // const timezone = moment.tz.
    callApi(
      'admin/stats/team_scans_by_hour',
      'POST',
      {
        user_id: this.state.user_data.admin_id,
        app_token: this.state.user_data.token,
        offset: timedifference,
        tz: timezone,
        is_exclude_duplicate: this.state.isExcludeDuplicateScans,
      },
      (response) => {
        if ('data' in response && 'today' in response.data) {
          let lineChartDataToday = {},
            lineChartDataAvg = {},
            goalsEveryHour = {};
          for (let i = 0; i < response.data.today.length; i++) {
            lineChartDataToday[this.convertHourToAMPM(response.data.today[i].time_hour)] = response.data.today[i].scans;
            const findAverageIndex = response.data.average.findIndex(
              (avg) => avg.time_hour === response.data.today[i].time_hour
            );
            lineChartDataAvg[this.convertHourToAMPM(response.data.today[i].time_hour)] =
              response.data.average[findAverageIndex].scans;

            goalsEveryHour[this.convertHourToAMPM(response.data.today[i].time_hour)] =
              this.state.goalsData.team.goal_scans_ideal_hourly;
          }

          // if ("team" in this.state.goalsData) {
          //   for (let i = 0; i <= 18; i++) {
          //     console.log(this.convertHourToAMPM(i), this.state.goalsData.team.goal_scans_ideal_hourly);
          //     goalsEveryHour[this.convertHourToAMPM(i)] = this.state.goalsData.team.goal_scans_ideal_hourly;
          //   }
          // } else {
          //   console.log(`No goals data`, this.state.goalsData);
          // }
          this.setState({
            scansByHour: [
              { name: 'Today', data: lineChartDataToday },
              { name: 'Goal', data: goalsEveryHour },
              { name: 'Usually by this hour', data: lineChartDataAvg },
            ],
          });
        }
      }
    );
  }

  initiate() {
    this.interval = setInterval(() => {
      this.loadGoalsData(() => this.loadScansByHour());
    }, this.state.timeOutInterval);
  }

  componentDidMount() {
    this.loadGoalsData(() => this.loadScansByHour());

    this.initiate();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateGoals(e) {
    e.preventDefault();

    this.setState({ isUpdatingGoals: true });

    const teamScansGoal =
      e.target.elements.teamScansGoal.value !== '' ? Number(e.target.elements.teamScansGoal.value) : null;
    const teamScansGoalIdealPerHour =
      e.target.elements.teamScansGoalIdealPerHour.value !== ''
        ? Number(e.target.elements.teamScansGoalIdealPerHour.value)
        : null;
    const teamScansGoalHourly =
      e.target.elements.teamScansGoalHourly.value !== '' ? Number(e.target.elements.teamScansGoalHourly.value) : null;
    // const teamProfitGoal =
    //   e.target.elements.teamProfitGoal.value !== "" ? Number(e.target.elements.teamProfitGoal.value) : null;
    const usersScansGoal =
      e.target.elements.usersScansGoal.value !== '' ? Number(e.target.elements.usersScansGoal.value) : null;

    callApi(
      'admin/teams/goals',
      'PUT',
      {
        goal_scans_ideal_hourly: teamScansGoalIdealPerHour,
        goal_scans_hourly: teamScansGoalHourly,
        goal_scans: teamScansGoal,
        // goal_profit: teamProfitGoal,
        goal_scans_users: usersScansGoal,
        user_id: this.state.user_data.admin_id,
        app_token: this.state.user_data.token,
      },
      (response) => {
        this.setState({ goalsData: response.data, isUpdatingGoals: false });
        if ('data' in response) {
          if (response.data.team.goal_scans === null) {
            console.log(`Empty Goals`, response.data.team);
            this.setState({ showGoalsModal: true });
          } else this.setState({ showGoalsModal: false });
        }
      }
    );
  }

  render() {
    const perGoalScans =
      'team' in this.state.goalsData
        ? ((this.state.goalsData.team.til_scans * 100) / this.state.goalsData.team.goal_scans).toFixed(0)
        : 0;
    // const perGoalProfit =
    //   "team" in this.state.goalsData
    //     ? ((this.state.goalsData.team.til_profit * 100) / this.state.goalsData.team.goal_profit).toFixed(0)
    //     : 0;
    const perGoalScansLastHour =
      'team' in this.state.goalsData
        ? ((this.state.goalsData.team.scan_last_hour * 100) / this.state.goalsData.team.goal_scans_hourly).toFixed(0)
        : 0;
    return (
      <React.Fragment>
        <TopMenu
          handleToggleExcludeDuplicate={(e) => {
            clearInterval(this.interval);

            this.setState(
              {
                isExcludeDuplicateScans: e.target.checked,
                timeOutInterval: e.target.checked ? 8000 : 3000,
              },
              () => this.initiate()
            );
          }}
          showGoalsModal={() => this.setState({ showGoalsModal: true, goalsModalClosable: true })}
          adminData={this.state.admin_data}
          history={this.props.history}
          activeKey="/"
        />

        <ModalGoals
          isUpdatingGoals={this.state.isUpdatingGoals}
          goalsModalClosable={this.state.goalsModalClosable}
          updateGoals={this.updateGoals}
          teamScansGoalHourly={'team' in this.state.goalsData ? this.state.goalsData.team.goal_scans_hourly : ''}
          teamScansGoal={'team' in this.state.goalsData ? this.state.goalsData.team.goal_scans : ''}
          teamScansGoalIdealPerHour={
            'team' in this.state.goalsData ? this.state.goalsData.team.goal_scans_ideal_hourly : ''
          }
          // teamProfitGoal={"team" in this.state.goalsData ? this.state.goalsData.team.goal_profit : ""}
          usersScansGoal={'team' in this.state.goalsData ? this.state.goalsData.team.goal_scans_users : ''}
          showGoalsModal={this.state.showGoalsModal}
          handleClose={() => this.setState({ showGoalsModal: false })}
        />
        <Container fluid={true} className="page-dashboard">
          <Row>
            <Col md={12}></Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col md={12}>
              <div style={{ display: 'flex' }}>
                <div className="dashDivs" style={{ textAlign: 'center', flex: 1.5 }}>
                  <div style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '30px' }}>
                    <i style={{ marginRight: '10px' }} className="fa fa-tachometer" /> SCANS PER HOUR
                  </div>
                  {'team' in this.state.goalsData && (
                    <ReactSpeedometer
                      maxSegmentLabels={4}
                      paddingVertical={0}
                      width={200}
                      height={200}
                      maxValue={this.state.goalsData.team.goal_scans_ideal_hourly}
                      value={this.state.goalsData.team.scan_per_hour.toFixed(0)}
                      needleColor="#222529"
                      startColor="#CB444A"
                      segments={10}
                      endColor="#408558"
                    />
                  )}
                </div>

                <div className="dashDivs" style={{ flex: 2.5 }}>
                  <div style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>
                    <i style={{ marginRight: '10px' }} className="fa fa-bolt" /> SCAN GOALS
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      paddingTop: '50px',
                    }}
                  >
                    <div className="moduleProgressBar">
                      <CustomContentProgressbar
                        styles={{
                          path: {
                            stroke: `#222529`,
                          },

                          text: {
                            fontSize: '13px',
                          },
                        }}
                        value={perGoalScansLastHour}
                      >
                        <div style={{ fontSize: '0.9em', textAlign: 'center' }}>
                          <div style={{ fontSize: '20px' }}>
                            <i className="fa fa-clock-o" />
                          </div>
                          <div style={{ marginTop: '7px' }}>
                            <strong>LAST HOUR</strong>
                          </div>
                        </div>
                        <div style={{ fontSize: '0.8em', marginTop: '5px' }}>{perGoalScansLastHour + '%'}</div>
                        <div style={{ marginTop: '7px', fontSize: '0.9em', fontWeight: 'bold' }}>
                          {'team' in this.state.goalsData
                            ? this.state.goalsData.team.scan_last_hour +
                              ' / ' +
                              this.state.goalsData.team.goal_scans_hourly
                            : '-'}
                        </div>
                      </CustomContentProgressbar>
                    </div>

                    <div className="moduleProgressBar">
                      <CustomContentProgressbar
                        styles={{
                          path: {
                            stroke: `#316CF4`,
                          },

                          text: {
                            fontSize: '13px',
                          },
                        }}
                        value={perGoalScans}
                      >
                        <div style={{ fontSize: '0.9em', textAlign: 'center' }}>
                          <div style={{ fontSize: '20px' }}>
                            <i className="fa fa-calendar-check-o" />
                          </div>
                          <div style={{ marginTop: '7px' }}>
                            <strong>TODAY</strong>
                          </div>
                        </div>
                        <div style={{ fontSize: '0.8em', marginTop: '5px' }}>{perGoalScans + '%'}</div>
                        <div style={{ marginTop: '7px', fontSize: '0.9em', fontWeight: 'bold' }}>
                          {'team' in this.state.goalsData
                            ? this.state.goalsData.team.til_scans + ' / ' + this.state.goalsData.team.goal_scans
                            : '-'}
                        </div>
                      </CustomContentProgressbar>
                    </div>
                    {/* <div className="moduleProgressBar">
                      <CustomContentProgressbar
                        styles={{
                          path: {
                            stroke: `#00743F`,
                          },

                          text: {
                            fontSize: "13px",
                          },
                        }}
                        value={perGoalProfit}
                      >
                        <div style={{ fontSize: "0.9em", textAlign: "center" }}>
                          <div style={{ fontSize: "20px" }}>
                            <i className="fa fa-money" />
                          </div>
                          <div style={{ marginTop: "7px" }}>
                            <strong>PROFIT GOAL</strong>
                          </div>
                        </div>
                        <div style={{ fontSize: "0.8em", marginTop: "5px" }}>{perGoalProfit + "%"}</div>
                        <div style={{ marginTop: "7px", fontSize: "0.9em", fontWeight: "bold" }}>
                          {"team" in this.state.goalsData
                            ? this.state.goalsData.team.til_profit + " / " + this.state.goalsData.team.goal_profit
                            : "-"}
                        </div>
                      </CustomContentProgressbar>
                    </div> */}
                  </div>
                </div>

                <div className="dashDivs" style={{ flex: 3 }}>
                  <div style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center' }}>
                    <i style={{ marginRight: '10px' }} className="fa fa-area-chart" /> SCAN PERFORMANCE
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <LineChart stye colors={['#406BEC', '#888686']} height="260px" data={this.state.scansByHour} />
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {'users' in this.state.goalsData && (
            <Row>
              <Col md={12}>
                <div style={{ display: 'flex' }}>
                  <div className="dashDivs" style={{ flex: 1 }}>
                    <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>
                      <i style={{ marginRight: '10px' }} className="fa fa-bars" /> TEAM PERFORMANCE
                    </div>

                    <div>
                      <PieChart data={this.state.userScansPie} />
                    </div>
                  </div>
                  <div className="dashDivs" style={{ flex: 3 }}>
                    {/* <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        marginBottom: "20px",
                        borderTop: "1px solid #ddd",
                        paddingTop: "20px",
                      }}
                    >
                      Leaderboard
                    </div> */}
                    <div style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '20px' }}>
                      <i style={{ marginRight: '10px' }} className="fa fa-bars" /> LEADERBOARD
                    </div>
                    <Table bordered>
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center' }}>Thumbnail</th>
                          <th>Name</th>
                          <th>Progress by Daily Goal</th>
                          <th style={{ textAlign: 'center' }}>Scans</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.goalsData.users.map((user) => (
                          <tr>
                            <td style={{ textAlign: 'center' }}>
                              {user.picture !== null && user.picture !== '' ? (
                                <img
                                  style={{ width: '50px', height: '50px' }}
                                  src={`https://files.palletiq.com/profile_images/${user.picture}`}
                                />
                              ) : (
                                `-`
                              )}
                            </td>
                            <td>{user.display_name}</td>
                            <td>
                              <ProgressBar
                                now={((user.scans * 100) / this.state.goalsData.team.goal_scans_users).toFixed(0)}
                                label={``}
                              />
                            </td>
                            <td style={{ textAlign: 'center' }}>{user.scans}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </React.Fragment>
    );
  }
}
export default Home;
