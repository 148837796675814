import React, { Component } from "react";
import { Container, Row, Col, Button, Spinner, Form } from "react-bootstrap";
import TopMenu from "../components/TopMenu";
import { callApi } from "../includes/Helper";
import auth from "../Auth";
import { Scatter, Pie } from "react-chartjs-2";
import ModalDownload from "../components/Reports/ModalDownload";
import ModalDonate from "../components/ModalDonate";
import "../assets/style/report.css";
import JSZip from "jszip";

class Report extends Component {
  state = {
    admin_data: auth.getAdminData(),

    scatterData: {},
    pieData: {},
    dudsList: [],
    noRanksList: [],
    reportData: [],
    minRank: 2000000,
    category: "gl_book",
    condition: "Used",
    maxUsedPrice: 20,
    minAge: 0,
    maxFBAUsedPrice: 10,
    isFBAUsedPriceFilterEnabled: false,
    isAgeFilterEnabled: false,
    isCondFilterEnabled: true,
    isCatFilterEnabled: true,
    showDownloadModal: false,
    showDonateModal: false,
    loadingReportData: false,
    isDownloading: false,
    shouldIncludeAllItems: false,
  };

  handleLogout = () => {
    auth.logout(() => {
      this.props.history.push("/");
    });
  };

  constructor() {
    super();
    this.loadReportData = this.loadReportData.bind(this);
    this.onFormSubmitDP = this.onFormSubmitDP.bind(this);
    this.updateChatPoints = this.updateChatPoints.bind(this);
    this.onFormSubmitDownloadFile = this.onFormSubmitDownloadFile.bind(this);
  }

  updateChatPoints() {
    const reportData = this.state.reportData;
    const minRank = this.state.minRank;
    const maxUsedPrice = this.state.maxUsedPrice;

    let dpStuds = [];
    let dpBNB = [];
    let dpLongTail = [];
    let dpDuds = [];
    let dudsList = [];
    let noRanksList = [];
    let qtyStuds = 0,
      qtyBNB = 0,
      qtyLongTail = 0,
      qtyDuds = 0;

    for (let i = 0; i < reportData.length; i++) {
      const dpRank = reportData[i][3];

      const lowestPriceMF = this.state.isCondFilterEnabled
        ? this.state.condition === "New"
          ? reportData[i][12]
          : reportData[i][4]
        : reportData[i][4];

      const lowestPriceFBA = this.state.isCondFilterEnabled
        ? this.state.condition === "New"
          ? reportData[i][13]
          : reportData[i][5]
        : reportData[i][5];

      const dpPrice =
        isNaN(lowestPriceMF) || lowestPriceMF <= 0 ? lowestPriceFBA : Math.min(lowestPriceMF, lowestPriceFBA);

      const dp = { x: dpRank, y: dpPrice };

      if (dpRank <= 0) {
        let noRankPoint = reportData[i];
        noRankPoint[4] = dpPrice;
        noRanksList.push(noRankPoint);
      }

      // Common filters: Age, Category, Condition

      const ageFilter = !this.state.isAgeFilterEnabled || reportData[i][11] >= this.state.minAge;
      const conditionFilter =
        !this.state.isCondFilterEnabled || this.state.condition === "" || reportData[i][7] === this.state.condition;
      const categoryFilter =
        !this.state.isCatFilterEnabled || this.state.category === "" || reportData[i][6] === this.state.category;
      const lowestFBAFilter =
        !this.state.isFBAUsedPriceFilterEnabled ||
        this.state.isFBAUsedPriceFilterEnabled === 0 ||
        reportData[i][5] < this.state.maxFBAUsedPrice;

      const doesPassAllChecks = ageFilter && conditionFilter && categoryFilter && lowestFBAFilter;
      const isFarTooWide = dpRank > 10000000 || dpPrice > 250;

      // Check for No rank and Non Books
      // if ((!this.state.shouldIncludeAllItems && dpRank <= 0) || reportData[i][7] !== "Used") continue;
      if (dpRank > minRank && dpPrice < maxUsedPrice && doesPassAllChecks) {
        // Keep duds data, no matter how ridiculous data points are (just not more than 10M tank)
        let dudPoint = reportData[i];
        dudPoint[4] = dpPrice;

        dudsList.push(dudPoint);
        qtyDuds += reportData[i][8];
        !isFarTooWide && dpDuds.push(dp);
      }

      // rest of the categories
      else if (dpRank < minRank && dpPrice < maxUsedPrice && doesPassAllChecks) {
        !isFarTooWide && dpBNB.push(dp);
        qtyBNB += reportData[i][8];
      } else if (dpRank < minRank && dpPrice > maxUsedPrice && doesPassAllChecks) {
        !isFarTooWide && dpStuds.push(dp);
        qtyStuds += reportData[i][8];
      } else if (dpRank > minRank && dpPrice > maxUsedPrice && doesPassAllChecks) {
        !isFarTooWide && dpLongTail.push(dp);
        qtyLongTail += reportData[i][8];
      }
    }

    this.setState({
      noRanksList,
      dudsList,
      pieData: {
        labels: [
          `Studs (${qtyStuds})`,
          `Bread & Butter (${qtyBNB})`,
          `Long Tail (${qtyLongTail})`,
          `Duds (${qtyDuds})`,
        ],
        datasets: [
          {
            label: "My First Dataset",
            data: [qtyStuds, qtyBNB, qtyLongTail, qtyDuds],
            backgroundColor: ["#47B8B8", "#FFE3AB", "#FFCAA1", "#FEACBC"],
            hoverOffset: 4,
          },
        ],
      },
      scatterData: {
        datasets: [
          {
            label: `Studs (${qtyStuds})`,
            backgroundColor: "#A2DBDB",
            borderColor: "#47B8B8",
            data: dpStuds,
          },
          {
            label: `Bread & Butter (${qtyBNB})`,
            backgroundColor: "#FFE3AB",
            borderColor: "#FFC75B",
            data: dpBNB,
          },
          {
            label: `Long Tail (${qtyLongTail})`,
            backgroundColor: "#FFCAA1",
            borderColor: "#FF9646",
            data: dpLongTail,
          },
          {
            label: `Duds (${qtyDuds})`,
            backgroundColor: "#FEACBC",
            borderColor: "#FE5B7B",
            data: dpDuds,
          },
        ],
      },
    });
  }

  async loadReportData() {
    this.setState({ loadingReportData: true });
    callApi(
      `reports/fetch/${this.props.match.params.id}`,
      {
        user_id: this.state.admin_data.id,
        app_token: this.state.admin_data.token,
      },
      (response) => {
        this.setState({ loadingReportData: false });

        if ("status" in response && response.status) {
          const reportData = JSON.parse(response.data.content);

          this.setState({ reportData }, () => {
            this.updateChatPoints();
          });
        } else {
          if (response.error && response.error === "Invalid Credentials") this.handleLogout();
          console.log(`Error downloading report file`, response);
        }
      }
    );
  }

  componentDidMount() {
    this.loadReportData();
  }

  onFormSubmitDP(e) {
    e.preventDefault();
    const minRank = parseInt(e.target.elements.minRank.value);
    const maxUsedPrice = parseFloat(e.target.elements.maxUsedPrice.value);
    const maxFBAUsedPrice = parseFloat(e.target.elements.maxFBAUsedPrice.value);
    const minAge = parseInt(e.target.elements.minAge.value);
    const category = e.target.elements.category.value;
    const condition = e.target.elements.condition.value;

    this.setState({ minRank, maxUsedPrice, maxFBAUsedPrice, minAge, category, condition }, () => {
      this.updateChatPoints();
    });
  }

  downloadDuds() {
    // downloading csv
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `MerchantSKU,ASIN,Title,SalesRank,Lowest MF Used,Lowest FBA Used,Sellable Qty,Unsellable Qty,Age\r\n`;

    for (let i = 0; i < this.state.dudsList.length; i++) {
      csvContent += `${this.state.dudsList[i][0]},${this.state.dudsList[i][1]},"${this.state.dudsList[i][2].replace(
        /[^a-zA-Z ]/g,
        ""
      )}",${this.state.dudsList[i][3]},${this.state.dudsList[i][4]},${this.state.dudsList[i][5]},${
        this.state.dudsList[i][9]
      },${this.state.dudsList[i][10]},${
        this.state.dudsList[i][11] === 0 ? `< 3 Months` : `> ${this.state.dudsList[i][11]} months`
      }\r\n`;
    }

    var encodedUri = encodeURI(csvContent);
    const fixedEncodedURI = encodedUri.replaceAll("#", "%23");
    var link = document.createElement("a");
    link.setAttribute("href", fixedEncodedURI);
    link.setAttribute("download", `duds_${this.props.match.params.id}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
  }

  downloadNoRankItems() {
    // downloading csv
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += `MerchantSKU,ASIN,Title,SalesRank,Lowest MF Used,Lowest FBA Used,Sellable Qty,Unsellable Qty\r\n`;

    for (let i = 0; i < this.state.noRanksList.length; i++) {
      csvContent += `${this.state.noRanksList[i][0]},${this.state.noRanksList[i][1]},"${this.state.noRanksList[
        i
      ][2].replace(/[^a-zA-Z ]/g, "")}",${this.state.noRanksList[i][3]},${this.state.noRanksList[i][4]},${
        this.state.noRanksList[i][5]
      },${this.state.noRanksList[i][9]},${this.state.noRanksList[i][10]}\r\n`;
    }

    var encodedUri = encodeURI(csvContent);
    const fixedEncodedURI = encodedUri.replaceAll("#", "%23");
    var link = document.createElement("a");
    link.setAttribute("href", fixedEncodedURI);
    link.setAttribute("download", `withoutrank_${this.props.match.params.id}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
  }

  padRemovalIndex(n, width, z) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  async onFormSubmitDownloadFile(evt) {
    evt.preventDefault();

    const remOrDis = evt.target.elements.rem_or_dep.value;
    const reportFileCount = Math.ceil(this.state.dudsList.length / 1000);

    this.setState({ isDownloading: true });

    callApi(
      `reports/download/${this.props.match.params.id}`,
      {
        user_id: this.state.admin_data.id,
        app_token: this.state.admin_data.token,
        address_name: evt.target.elements.address_name.value,
        address_field_one: evt.target.elements.address_field_one.value,
        address_field_two: evt.target.elements.address_field_two.value,
        address_field_three: evt.target.elements.address_field_three.value,
        address_city: evt.target.elements.address_city.value,
        address_country_code: evt.target.elements.address_country_code.value,
        address_state: evt.target.elements.address_state.value,
        address_postal_code: evt.target.elements.address_postal_code.value,
        contact_phone: evt.target.elements.contact_phone.value,
        shipping_notes: evt.target.elements.shipping_notes.value,
        report_file_count: reportFileCount,
      },
      (response) => {
        if ("status" in response && response.status) {
          // updating last used user data
          this.setState({ admin_data: response.user });
          auth.setAdminData(response.user);

          // downloading csv
          let csvContentHeader = "data:text/csv;charset=utf-8,";
          let csvContent = `RemovalDisposition\t${remOrDis}\r\n`;
          csvContent += `MerchantRemovalOrderID\tPOSIQ${this.padRemovalIndex(response.user.removal_index, 5)}\r\n`;
          csvContent += `AddressName\t${response.user.last_used_address_name}\r\n`;
          csvContent += `AddressFieldOne\t${response.user.last_used_address_field_one}\r\n`;
          csvContent += `AddressFieldTwo\t${
            response.user.last_used_address_field_two !== null ? response.user.last_used_address_field_two : ""
          }\r\n`;
          csvContent += `AddressFieldThree\t${
            response.user.last_used_address_field_three !== null ? response.user.last_used_address_field_three : ""
          }\r\n`;
          csvContent += `AddressCity\t${response.user.last_used_address_city}\r\n`;
          csvContent += `AddressCountryCode\t${response.user.last_used_address_country_code}\r\n`;
          csvContent += `AddressStateOrRegion\t${response.user.last_used_address_state}\r\n`;
          csvContent += `AddressPostalCode\t${response.user.last_used_address_postal_code}\r\n`;
          csvContent += `ContactPhoneNumber\t${response.user.last_used_contact_phone}\r\n`;
          csvContent += `ShippingNotes\t${
            response.user.last_used_shipping_notes !== null ? response.user.last_used_shipping_notes : ""
          }\r\n\r\n`;

          csvContent += `MerchantSKU\tSellableQuantity\tUnsellableQuantity\r\n`;

          if (this.state.dudsList.length <= 1000) {
            let singleCSVContent = csvContentHeader + csvContent;
            for (let i = 0; i < this.state.dudsList.length; i++) {
              singleCSVContent += `${this.state.dudsList[i][0]}\t${this.state.dudsList[i][9]}\t${this.state.dudsList[i][10]}\r\n`;
            }

            const encodedUri = encodeURI(singleCSVContent);
            const fixedEncodedURI = encodedUri.replaceAll("#", "%23");
            var link = document.createElement("a");
            link.setAttribute("href", fixedEncodedURI);
            link.setAttribute("download", `report_${this.props.match.params.id}.txt`);
            document.body.appendChild(link); // Required for FF

            link.click();
          } else {
            let zip = new JSZip();

            let dudsList = [...this.state.dudsList];
            for (let i = 0, fileIndex = 1; i < dudsList.length; i += 1000, fileIndex += 1) {
              const slicedCSV = dudsList.slice(i, i + 1000);
              let csvContent = `RemovalDisposition\t${remOrDis}\r\n`;
              csvContent += `MerchantRemovalOrderID\tPOSIQ${this.padRemovalIndex(
                Number(response.user.removal_index) - reportFileCount + fileIndex,
                5
              )}\r\n`;
              csvContent += `AddressName\t${response.user.last_used_address_name}\r\n`;
              csvContent += `AddressFieldOne\t${response.user.last_used_address_field_one}\r\n`;
              csvContent += `AddressFieldTwo\t${
                response.user.last_used_address_field_two !== null ? response.user.last_used_address_field_two : ""
              }\r\n`;
              csvContent += `AddressFieldThree\t${
                response.user.last_used_address_field_three !== null ? response.user.last_used_address_field_three : ""
              }\r\n`;
              csvContent += `AddressCity\t${response.user.last_used_address_city}\r\n`;
              csvContent += `AddressCountryCode\t${response.user.last_used_address_country_code}\r\n`;
              csvContent += `AddressStateOrRegion\t${response.user.last_used_address_state}\r\n`;
              csvContent += `AddressPostalCode\t${response.user.last_used_address_postal_code}\r\n`;
              csvContent += `ContactPhoneNumber\t${response.user.last_used_contact_phone}\r\n`;
              csvContent += `ShippingNotes\t${
                response.user.last_used_shipping_notes !== null ? response.user.last_used_shipping_notes : ""
              }\r\n\r\n`;

              csvContent += `MerchantSKU\tSellableQuantity\tUnsellableQuantity\r\n`;

              for (let j = 0; j < slicedCSV.length; j++) {
                csvContent += `${slicedCSV[j][0]}\t${slicedCSV[j][9]}\t${slicedCSV[j][10]}\r\n`;
              }

              zip.file(`report_${this.props.match.params.id}_${fileIndex}.txt`, csvContent);
            }

            zip.generateAsync({ type: "base64" }).then(function (content) {
              window.location.href = "data:application/zip;base64," + content;
            });
          }
        } else {
          if (response.error && response.error === "Invalid Credentials") this.handleLogout();
          console.log(`Error downloading report file`, response);
        }
        this.setState({ showDownloadModal: false, isDownloading: false });
      }
    );
  }
  // initiateReportDownload() {}

  render() {
    return (
      <React.Fragment>
        <TopMenu
          //shouldIncludeAllItems={this.shouldIncludeAllItems}
          headerTitle={this.props.history.location.state?.reportName + "[#" + this.props.match.params.id + "]"}
          adminData={this.state.admin_data}
          history={this.props.history}
          activeKey="/report"
        />

        <ModalDonate
          proceedDownload={() => {
            this.setState({ showDonateModal: false, showDownloadModal: true });
          }}
          handleDonation={this.state.handleDonation}
          showDonateModal={this.state.showDonateModal}
          handleClose={() => this.setState({ showDonateModal: false })}
        />

        <ModalDownload
          isDownloading={this.state.isDownloading}
          adminData={this.state.admin_data}
          onFormSubmitDownloadFile={this.onFormSubmitDownloadFile}
          downloadingReportId={this.props.match.params.id}
          showDownloadModal={this.state.showDownloadModal}
          handleClose={() => this.setState({ showDownloadModal: false })}
        />

        <Container fluid={true} className="page-dashboard">
          <Row style={{ marginTop: "10px" }}>
            <Col md={12}>
              <div style={{ paddingBottom: "10px", borderBottom: "1px solid #ddd", marginBottom: "10px" }}>
                <div style={{ fontWeight: "bold" }}>Remove all inventory that meets the following criteria:</div>
                <Row style={{ marginTop: "10px" }}>
                  <Col md={12}>
                    <Form onSubmit={this.onFormSubmitDP}>
                      <Row>
                        <Col md={2}>
                          <div className="filter-description" style={{ fontWeight: "bold" }}>
                            Sales Rank greater than:
                          </div>
                          <Form.Group controlId="formMinScore">
                            {/* <Form.Label></Form.Label> */}
                            <Form.Control
                              defaultValue={this.state.minRank}
                              required
                              min={0}
                              type="number"
                              name="minRank"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={2} style={{ borderRight: "1px solid #ddd" }}>
                          <div className="filter-description" style={{ fontWeight: "bold" }}>
                            Lowest Used Price less than:
                          </div>
                          <Form.Group controlId="formMinScore">
                            <Form.Control
                              defaultValue={this.state.maxUsedPrice}
                              required
                              min={0}
                              type="number"
                              step="0.01"
                              name="maxUsedPrice"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={2}>
                          <div className="filter-description">
                            <Form.Check
                              onChange={(e) => {
                                this.setState(
                                  {
                                    isFBAUsedPriceFilterEnabled: e.target.checked,
                                  },
                                  () => {
                                    this.updateChatPoints();
                                  }
                                );
                              }}
                              type="switch"
                              name="isLowestUsedFBAEnabled"
                              id="is-lowest-used-fba-enabled"
                              label="Lowest Used FBA Price less than:"
                            />
                          </div>

                          <Form.Group controlId="formMinScore">
                            <Form.Control
                              defaultValue={this.state.maxFBAUsedPrice}
                              required
                              disabled={!this.state.isFBAUsedPriceFilterEnabled}
                              type="number"
                              step="0.01"
                              name="maxFBAUsedPrice"
                            />
                          </Form.Group>
                        </Col>

                        <Col md={2}>
                          <div className="filter-description">
                            <Form.Check
                              onChange={(e) => {
                                this.setState(
                                  {
                                    isAgeFilterEnabled: e.target.checked,
                                  },
                                  () => {
                                    this.updateChatPoints();
                                  }
                                );
                              }}
                              type="switch"
                              name="isAgeFilter"
                              id="is-age-filter"
                              label="Age:"
                            />
                          </div>

                          <Form.Group controlId="formMinAge">
                            <Form.Control as="select" disabled={!this.state.isAgeFilterEnabled} name="minAge">
                              <option value="0">{`All`}</option>
                              <option value="3">{`> 3 Months`}</option>
                              <option value="6">{`> 6 Months`}</option>
                              <option value="9">{`> 9 Months`}</option>
                              <option value="12">{`> 12 Months`}</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md={2}>
                          <div className="filter-description">
                            <Form.Check
                              onChange={(e) => {
                                this.setState(
                                  {
                                    isCatFilterEnabled: e.target.checked,
                                  },
                                  () => {
                                    this.updateChatPoints();
                                  }
                                );
                              }}
                              checked={this.state.isCatFilterEnabled}
                              type="switch"
                              name="isCatFilter"
                              id="is-cat-filter"
                              label="Category:"
                            />
                          </div>

                          <Form.Group controlId="formCategory">
                            <Form.Control
                              as="select"
                              disabled={!this.state.isCatFilterEnabled}
                              name="category"
                              defaultValue="gl_book"
                            >
                              <option value="">All</option>
                              <option value="gl_book">Books</option>
                              <option value="gl_music">Music</option>
                              <option value="gl_dvd">DVD</option>
                              <option value="gl_video_game">Video Games</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>

                        <Col md={2}>
                          <div className="filter-description">
                            <Form.Check
                              onChange={(e) => {
                                this.setState(
                                  {
                                    isCondFilterEnabled: e.target.checked,
                                  },
                                  () => {
                                    this.updateChatPoints();
                                  }
                                );
                              }}
                              checked={this.state.isCondFilterEnabled}
                              type="switch"
                              name="isCondFilter"
                              id="is-cond-filter"
                              label="Condition:"
                            />
                          </div>

                          <Form.Group controlId="formCategory">
                            <Form.Control
                              as="select"
                              disabled={!this.state.isCondFilterEnabled}
                              name="condition"
                              defaultValue="Used"
                            >
                              <option value="">Any</option>
                              <option value="New">New</option>
                              <option value="Used">Used</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          {!this.state.loadingReportData && (
                            <div>
                              <div>
                                <Button size="sm" variant="danger" onClick={() => this.downloadNoRankItems()}>
                                  <i className="fa fa-download" /> No Rank
                                </Button>
                                <Button
                                  size="sm"
                                  variant="secondary"
                                  onClick={() => this.downloadDuds()}
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i className="fa fa-download" /> Duds
                                </Button>
                                <Button
                                  size="sm"
                                  variant="success"
                                  onClick={() => this.setState({ showDonateModal: true })}
                                  style={{ marginLeft: "20px" }}
                                >
                                  <i className="fa fa-download" /> Report
                                </Button>
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ fontSize: "13px" }}
                                  href="https://sellercentral.amazon.com/gp/basic-fulfillment/upload-removal-create.html?language=en_US"
                                >
                                  Click here to upload the removal / disposal report on amazon
                                </a>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col md={6} style={{ textAlign: "right" }}>
                          <div style={{ height: "100%" }}>
                            <Button type="submit" variant="primary">
                              Update
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>

              <div>
                {this.state.loadingReportData ? (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Spinner
                      style={{ width: 100, height: 100, margin: "20px auto" }}
                      animation="border"
                      role="status"
                    ></Spinner>
                  </div>
                ) : (
                  <Row>
                    <Col md={3}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <Pie data={this.state.pieData} />
                      </div>
                    </Col>
                    <Col md={9}>
                      <Scatter
                        data={this.state.scatterData}
                        options={{
                          responsive: true,

                          scales: {
                            y: {
                              ticks: {
                                // Include a dollar sign in the ticks
                                callback: function (value, index, values) {
                                  return "$" + value;
                                },
                              },
                              title: {
                                display: true,
                                text: "Lowest MF Used Price",
                              },
                            },
                            x: {
                              title: {
                                display: true,
                                text: "Salesrank",
                              },
                            },
                          },
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
export default Report;
