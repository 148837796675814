import { apiBaseUrlLocal } from './AppConfig';
import axios from 'axios';

export const submitLogin = async (username, password) => {
  try {
    console.log(`Submitting LOGIN`);
    const response = await fetch(apiBaseUrlLocal + 'admin/login', {
      method: 'POST',
      body: new URLSearchParams({
        username: username,
        password: password,
      }),
    });
    const data = await response.json();
    if (data.status) return { status: true, data };
    else if (data.error) return { status: false, error: data.error };
    else return { status: false, error: 'Server Error. Please try in some time.' };
  } catch (err) {
    return { status: false, error: err };
  }
};

export const submitSignup = async (first_name, last_name, username, password) => {
  const response = await fetch(apiBaseUrlLocal + 'user/signup', {
    method: 'POST',
    body: new URLSearchParams({
      first_name,
      last_name,
      username: username,
      password: password,
    }),
  });
  const data = await response.json();
  return data;
};

export const submitNewReport = async (reportName, reportFile, admin_data) => {
  const data = new FormData();
  data.append('name', reportName);
  data.append('user_id', admin_data.id);
  data.append('app_token', admin_data.token);
  data.append('report', reportFile);
  try {
    let res = await axios.post(apiBaseUrlLocal + 'reports/', data);
    const dataRes = await res.data;
    return dataRes;
  } catch (err) {
    console.log(`Error submitting report`, err);
    return false;
  }
};

export const callApi = (endpoint, method, options, callback) => {
  fetch(apiBaseUrlLocal + endpoint, {
    method,
    body: new URLSearchParams(options),
  })
    .then((resp) => resp.json())
    .then((data) => {
      callback(data);
    });
};

// export const getInfoCounts = (userId, token, callback) => {
//   fetch(apiBaseUrlLocal + "stats/counts", {
//     method: "POST",
//     body: new URLSearchParams({
//       user_id: userId,
//       app_token: token,
//     }),
//   })
//     .then((resp) => resp.json())
//     .then((data) => {
//       callback(data);
//     });
// };

// export const getGeoData = async (userId, token, callback) => {
//   await fetch(apiBaseUrlLocal + "stats/geography", {
//     method: "POST",
//     body: new URLSearchParams({
//       user_id: userId,
//       app_token: token,
//     }),
//   })
//     .then((resp) => resp.json())
//     .then((data) => {
//       callback(data);
//     });
// };
